import { ButtonBase } from "@material-ui/core";
import { Link } from "gatsby";
import {
  GatsbyImage,
  IGatsbyImageData,
  StaticImage,
} from "gatsby-plugin-image";
import React from "react";

interface ServiceCardProps {
  title: string;
  image?: IGatsbyImageData;
  alt?: string;
  to: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  alt = "Service image",
  image,
  to,
  children,
}) => {
  return (
    <div className="border border-gray-200 hover:shadow-md hover:border-t-2 hover:border-gray-300 hover:mt-[-1px] transition-all ">
      <ButtonBase className="block group h-full" component={Link} to={to}>
        <div className="mb-4 pt-6 ">
          {typeof image === "undefined" ? (
            <StaticImage
              src="../../../images/home-additions.png"
              alt={alt}
              layout="fixed"
              placeholder="tracedSVG"
              width={96}
              className="mx-auto"
            />
          ) : (
            <GatsbyImage image={image} alt={title} className="mx-auto" />
          )}
        </div>
        <div className="text-center px-6 pb-6">
          <h4 className="text-xl font-semibold mb-2">{title}</h4>
          <p className="mb-2">{children}</p>
          <span className="text-primary-main group-hover:text-primary-dark font-medium">
            Learn more
          </span>
        </div>
      </ButtonBase>
    </div>
  );
};

export default ServiceCard;
