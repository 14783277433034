import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SectionIntro from "../components/ui/SectionIntro/SectionIntro";
import ServiceCard from "../components/ui/Services/ServiceCard";
import { getSingleImageBySrc, Images } from "../utils/findImage";

import { graphql } from "gatsby";
import { HomepageData } from "../fragments/homepageFields";

interface ServicesPageProps {
  data: {
    prismicHomepage: HomepageData;
  };
  location: Location;
}

const ServicesPage: React.FC<ServicesPageProps> = ({ data, location }) => {
  const homepageData = data.prismicHomepage.data;

  return (
    <Layout>
      <SEO
        title="Services"
        description="Mogk Carpentry specializes in custom homes and renovations services in Embro Ontario for residential and commercial clients."
        slug={location.pathname}
      />

      <section className="bg-gray-800">
        <div className="container">
          <h1 className="text-4xl text-white leading-none mb-0">Services</h1>
        </div>
      </section>

      {homepageData.body.map((slice) => {
        if (slice.__typename === "PrismicHomepageBodyServices") {
          return (
            <section key={slice.__typename} className="bg-gray-50">
              <div className="container">
                <div className="grid sm:grid-cols-2 xl:grid-cols-4 grid-cols-1 gap-6 justify-items-center">
                  <div className="col-span-full">
                    <SectionIntro
                      subHeading={slice.primary.section_intro_sub_heading.text}
                      heading={slice.primary.section_intro_heading.text}
                      className="text-center mb-4"
                    ></SectionIntro>
                  </div>

                  {slice.items.map((service, index) => (
                    <ServiceCard
                      key={`${service.related_service.document.uid}-${index}`}
                      to={`/services/${service.related_service.document.uid}/`}
                      title={service.service_name.text}
                      image={
                        service.image.localFile.childImageSharp.gatsbyImageData
                      }
                      alt={service.image.alt || service.service_name.text}
                    >
                      {service.service_description.text}
                    </ServiceCard>
                  ))}
                </div>
              </div>
            </section>
          );
        } else return null;
      })}
    </Layout>
  );
};

export default ServicesPage;

export const pageQuery = graphql`
  query {
    prismicHomepage {
      id
      data {
        title {
          text
        }
        seo {
          seo_title
          seo_description
        }
        body {
          __typename

          ... on PrismicHomepageBodyServices {
            ...homepageServicesFields
          }
        }
      }
    }
    prismicContactInfo {
      ...contactInfoFields
    }
  }
`;
